@import "./common";
@import './responsive';

h2.biography-name {
    @include space;
}

dl.biography-profile {
    @include space;
    @include float-container;

    font-weight: bold;

    dt  {
        float: left;
        clear: both;
        width: 6em;
    }

    dd {
        float: left;
    }

}

section.biography-history {
    @include space;

    > section {
        margin: 1em 0 0 1ex;

        ul {
            list-style: circle outside;
            margin-left: 1em;
        }

        &.work {
            padding-left: 0;

            h5 {
                font-size: 1rem;
            }

            section {
                margin: 0;
            }
        }
    }
}
