
$logoComputerWidth: 111px;
$logoComputerHeight: 112px;
$logoPhoneWidth: 60px;
$logoPhoneHeight: 61px;

$normalLinkColor: #5080ff;
$visitedLinkColor: #bb44ee;
$activeLinkColor: #ff2299;

$menuComputerWidth: 200px;
$menuPhoneHeight: 2em;
$footerPhoneHeight: 2em;

$commonBackgroundColor: #ffffff;
$commonForegroundColor: #333333;

*.alert {
    border: 1px solid #ff8800;
}


@mixin space {
    margin: 10pt 20pt;
}

@mixin float-container {
    &:after {
        clear: both;
        content: '.';
        display: block;
        height: 0;
        visibility: hidden;
    }
}

@mixin debug {
    border: 1px solid red;
}

@mixin menu-link {
    a {
        padding: 1ex 0;
        display: block;
        height: 100%;
        width: 100%;
        font-weight: bold;
        font-size: 1.3rem;
        line-height: 100%;

        $menuLinkColor: #000000;

        &:link {
            color: $menuLinkColor;
        }

        &:visited {
            color: $menuLinkColor;
        }

        &:after {
            display: none;
        }

        &:hover {
            text-shadow: 0px 0px 6px #888888;
        }

        &:active {
            text-shadow: 0px 0px 6px #444444;
            background-color: transparent;
        }

    }

    li {
        text-align: center;
    }

    li a img {
        background-color: #222222;
        height: 30px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
