@import "./common";
@import './responsive';

#index .swiper-wrapper {
    img {
        width: 100%;
        box-shadow: none;
        margin: 0;
    }
}
