@import "./common";
@import './responsive';

html {
    font-size: 12.5pt;
}

body {
    font-family: 'Verdana','Skia-Regular_Condensed','Tahoma','Meiryo UI','メイリオ', Meiryo,'Osaka',YuGothic,'Yu Gothic',sans-serif;
    line-height: 1.3em;
    color: $commonForegroundColor;
    background-color: $commonBackgroundColor;
}

html, body {
    height: 100%;
    min-height: 100%;
}

img {
    max-width: 100%;

    @include media($mediaTablet) {
        $size: 4px;
        box-shadow: 0px 0px $size 2px rgba(0,0,0,0.75);
        margin: $size;
    }
    @include media($mediaComputer) {
        $size: 10px;
        box-shadow: 0px 0px $size 2px rgba(0,0,0,0.75);
        margin: $size;
    }
}

h1 {
    font-size: 140%;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;

        @include media($mediaPhone) {
            width: 100%;
        }
    }
}
h2 {
    font-size: 120%;
}
h3 {
    font-size: 110%;
}
h3 {
    font-size: 105%;
}

ul, ol {
    list-style: none;
}

a {
    display: inline-block;
    position: relative;
    text-decoration: none;

    &:link {
        color: $normalLinkColor;
    }

    &:visited {
        color: $visitedLinkColor;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 1px;
        transform: scaleX(0);
        background-color: $activeLinkColor;
        transition: all 0.3s ease;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
        }
    }

    &:active {
        background-color:$activeLinkColor;
        transition: all 0.2s ease;
    }
}

p + p {
    margin-top: 1em;

    @include media($mediaPhone) {
        margin-top: 0.5em;
    }
}

#menu {
    $paddingComputerVerical: 10px;

    position: fixed;
    top: 0;
    right: 0;
    //bottom: dummy;
    left: 0;
    width: $menuComputerWidth;
    height: 100%;

    //background: url('../assets/logo@computer.png') no-repeat top $paddingComputerVerical center / #{$logoComputerWidth} #{$logoComputerHeight};
    padding-left: 0;
    padding-top: $paddingComputerVerical * 2; // + $logoComputerHeight;

    @include media($mediaPhone) {
        position: absolute;
        top: -$menuPhoneHeight;
        right: 0;
        //bottom: dummy
        left: 0;
        height: $menuPhoneHeight;
        //width: calc(100% - #{$menuPhoneHeight});
        width: 100%;

        vertical-align: middle;

        //background: url('../assets/logo@phone.png') no-repeat left center / contain;
        //padding-left: $menuPhoneHeight;
        padding-top: 0;
    }

    nav {
        @include media($mediaPhone) {
            height: 100%;
            width: 100%;
        }

        ul {
            padding: 0 2ex;
            text-align: center;

            @include media($mediaPhone) {
                padding: 0;
                height: 100%;
            }

            li {
                @include menu-link;

                display: block;
                width: 100%;
                height: 100%;

                @include media($mediaPhone) {
                    display: inline-block;
                    width: 50%;
                    vertical-align: bottom;
                }
            }
        }
    }
}

#root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: $menuComputerWidth;

    @include media($mediaPhone) {
        position: absolute;
        top: $menuPhoneHeight;
        right: 0;
        left: 0;
        width: 100%;
        //margin-bottom: $footerPhoneHeight;
    }
}

//#content {
    /*
    @include media($mediaPhone) {
        min-height: 100%;
    }
    */
//}

#footer {
    position: fixed;
    //top: dummy;
    right: 0;
    bottom: 0;
    left: 0;
    width: $menuComputerWidth;

    font-size: 0.6em;

    @mixin link-content {
        li.twitter a::before {
            content: 'Twitter';
        }
        li.instagram a:before {
            content: 'Instagram';
        }
        li.facebook a:before {
            content: 'Facebook';
        }
    }

    section.sns {
        @include media($mediaTablet) {
            @include menu-link;
            @include link-content;
        }
        @include media($mediaComputer) {
            @include menu-link;
            @include link-content;
        }


    }

    ul.copyright {
        list-style: none;

        li {
            text-align: center;
        }
    }

    @include media($mediaPhone) {
        position: static;
        width: 100%;

        section.sns {
            &:before {
                content: ('Fugee');
                font-family: 'Libre Baskerville';
                font-size: 4rem;
                line-height: 300%;
                display: block;
                background: #000000;
                color: $commonBackgroundColor;
                text-align: center;
            }

            ul {
                margin: auto initial;
                li {
                    background-color: #333333;
                    border: 1px solid $commonBackgroundColor;
                    width: calc((100% / 3) - 2px);
                    display: inline-block;

                    a {
                        display: block;
                        padding: 20%;
                    }
                }
            }
        }

        ul.copyright {
            //position: static;
            height: $footerPhoneHeight;
            width: 100%;

            background: linear-gradient(to bottom, #feffff 0%,#eeeeee 40%,#cccccc 100%);
        }
    }
}

