@import "./common";
@import './responsive';

@mixin live-content-header {
    text-align: center;
    font-size: 120%;
    padding: 3pt;
    font-weight: bold;
}

@mixin live-content-detail {
    font-size: 70%;
    line-height: 110%;
}

$liveContentTitleBackcolor: #888888;

@mixin live-content-detail-title {
    color: #ffffff;
    background: $liveContentTitleBackcolor;
    text-align: center;
    padding: 0.5ex;
}

section.live-item {
    + section.live-item {
        margin-top: 2em;

        &:before {
            margin-bottom: 2em;
            display: block;
            content: ' ';
            border-top: 4px dotted #444;
        }
    }

    > p, > dl {
        @include space;
    }

    > dl dd + dt {
        margin-top: 1em;
    }

    td {
        padding: 0.5ex;
    }

    .title {
        text-align: center;
    }

    h2.catchphrase {
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
    }

    dd > * {
        width: 100%;
    }

    dt.guest {
        @include live-content-header;

        background: #000000;
        color: #ffffff;
    }
    dd.guest {
        text-align: center;
        background: #eeeeee;
        color: #000000;
        padding: 1em 2em;

        @include media($mediaPhone) {
            padding: 1ex 1em;
        }

        section {
            + section {
                margin-top: 1em;
            }

            ul.main {
                li {
                    display: block;
                }
            }
            ul.back {
                li {
                    display: inline;
                }

                li + li {
                    margin-left: 1ex;
                }
            }
        }

    }

    dt.schedule {
        @include live-content-header;
    }
    dd.schedule {
        @include live-content-detail;

        table {
            border-collapse: collapse;

            thead {
                th {
                    @include live-content-detail-title;
                    vertical-align: middle;
                }
            }
            tbody {
                td {
                    border: 1px solid $liveContentTitleBackcolor;
                }
            }
        }
    }

    dt.ticket {
        @include live-content-header;
    }
    dd.ticket {
        @include live-content-detail;

        table {
            border-collapse: collapse;

            th {
                @include live-content-detail-title;
                border: 1px solid $liveContentTitleBackcolor;
                vertical-align: middle;
            }
            td {
                border: 1px solid $liveContentTitleBackcolor;
            }
        }
    }
}

