@import './responsive';

.swiper-wrapper {
    img {
        @include media($mediaComputer) {
            width: 65%;
        }
    }
}


